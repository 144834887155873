// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function anchorScroll() {
  if ($('.anchor').length) {
    $('.anchor').each(function () {
      var top_of_element = $(this).offset().top;
      var top_of_screen = $(window).scrollTop();
      if (top_of_screen + 120 >= top_of_element) {
        $('.anchor-link').removeClass('is-active');
        $('.anchor-link[data-anchor="#' + $(this).attr('id') + '"]').addClass(
          'is-active'
        );
      } else {
        // the element is not visible, do something
      }
    });
  }
}

function formModal() {
  const modal = $('#form-modal');
  if (!modal.length) return;
  const timer = 30 * 1000;

  function openModal() {
    const isSeen = sessionStorage.getItem('form-modal-seen');
    modal.on('hidden.bs.modal', function () {
      sessionStorage.setItem('form-modal-seen', 'true');
    });
    if (isSeen && isSeen === 'true') return;
    modal.modal();
  }

  setTimeout(openModal, timer);
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  formModal();
  var afterHashtag = window.location.hash;
  $(afterHashtag).trigger('click');
  $('a[data-anchor="' + afterHashtag + '"]').trigger('click');
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  $('.nav-pills .nav-link').on('click', function () {
    window.scrollTo({ top: 0 });
  });

  let video = $('#hero-video');
  video.on('ended', function () {
    $(this).fadeOut('2000', function () {
      $(this).css('opacity', 0);
    });
  });

  // Gravity form Letter field only need add class to text field in GForm "gf-letter-only"
  $('.gf-letter-only input').bind('keyup blur', function () {
    var node = $(this);
    node.val(node.val().replace(/[^A-z ]/g, ''));
  });
  // end letter field only

  $('.dropdown-item,.nav-list__link').on('click', function () {
    let url = $(this).attr('href');
    var id = url && url.indexOf('#') >= 0 ? '#' + url.split('#')[1] : null;
    if (
      $(id).hasClass('nav-link') ||
      $(id).hasClass('nav-list__link') ||
      $('a[data-anchor="' + id + '"]')
    ) {
      $(id).trigger('click');
      $('a[data-anchor="' + id + '"]').trigger('click');
    }
  });
  // body lock mobile for menu
  $('.navbar-toggler').on('click', function () {
    $('body').toggleClass('lock');
    $('.navbar').toggleClass('show');
    $(this).toggleClass('active');
  });

  $('#pills-tab button').on('click', function (event) {
    event.preventDefault();
    $(this).tab('show');
  });

  let anchorButton = $('.anchor-link');
  anchorButton.on('click', function () {
    anchorButton.removeClass('is-active');
    $(this).addClass('is-active');
  });

  //slider
  $('.our-customers__slider').slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          infinite: true,
          dots: false,
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 560,
        settings: {
          infinite: true,
          dots: false,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  });
  //features slider
  $('.features-slider__block').slick({
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 620,
        settings: {
          autoplay: true,
          speed: 700,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  });
  //slider testimonials
  $('.testimonials__slider').slick({
    infinite: true,
    dots: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
  $('.home-customer-slider').slick({
    infinite: true,
    dots: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          infinite: true,
          dots: false,
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          rows: 3,
        },
      },
      {
        breakpoint: 560,
        settings: {
          infinite: true,
          dots: false,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 3,
        },
      },
    ],
  });
  $('.menu-item-has-children a').on('click', function () {
    // $(this).siblings('.sub-menu-wrapper').toggle();
    $(this).toggleClass('active-item');
    let title = $(this).children('span').html();
    let subMenu = $(this).parent('li').find('.dropdown-menu');
    subMenu.attr('data-title', title);
  });

  const inViewport = (elem) => {
    let allElements = document.getElementsByClassName(elem);
    let windowHeight = window.innerHeight;
    const elems = () => {
      for (let i = 0; i < allElements.length; i++) {
        //  loop through the sections
        let viewportOffset = allElements[i].getBoundingClientRect(); //  returns the size of an element and its position relative to the viewport
        let top = viewportOffset.top; //  get the offset top
        // let section_id = '#';
        if (top < windowHeight) {
          //  if the top offset is less than the window height
          allElements[i].classList.add('in-viewport'); //  add the class
        } else {
          allElements[i].classList.remove('in-viewport'); //  remove the class
        }
      }
    };
    elems();
    window.addEventListener('scroll', elems);
  };

  inViewport('view'); //  run the function on all section elements

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  ScrollOut({
    offset: function () {
      return window.innerHeight - 200;
    },
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  anchorScroll();
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */

$(window).on('scroll', function () {
  anchorScroll();
  // jQuery code goes here
  if ($(this).scrollTop() > 1) {
    $('.header').addClass('sticky');
  } else {
    $('.header').removeClass('sticky');
  }

  //  Body Anchor
  if ($(this).scrollTop() > 50) {
    $('.body-anchor').addClass('active-anchor');
  } else {
    $('.body-anchor').removeClass('active-anchor');
  }
});
